.userLogoContainer {
  height: 35px;
  width: 35px;

  border: 2px solid lightblue;
  border-radius: 50%;

  background-color: #a0ffd6;

  display: flex;
  justify-content: center;
  align-items: center;

}
.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}
.userLogoContainerMobile {
  height: 35px;
  width: 35px; 
}
.userLogoContainer > span {
  font-weight: 500;
  font-size: 13px;
  line-height: .26px;
  color: #38364b;
  text-transform: uppercase;
}
.userLogoContainer:hover {
  cursor: pointer;
}