@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
@import url(https://fonts.googleapis.com/css?family=Lato:700);
.Logo_userLogoContainer__30KAT {
  height: 35px;
  width: 35px;

  border: 2px solid lightblue;
  border-radius: 50%;

  background-color: #a0ffd6;

  display: flex;
  justify-content: center;
  align-items: center;

}
.Logo_logoContainer__3IuCW {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
}
.Logo_userLogoContainerMobile__2gTBy {
  height: 35px;
  width: 35px; 
}
.Logo_userLogoContainer__30KAT > span {
  font-weight: 500;
  font-size: 13px;
  line-height: .26px;
  color: #38364b;
  text-transform: uppercase;
}
.Logo_userLogoContainer__30KAT:hover {
  cursor: pointer;
}
.Header_headerContainer__379ep {
  height: 70px;
  background: #272729;
  width: 100%;
}
.Header_headerContainerMobile__2h_6a {
  padding: 0 15px;
}
.Header_headerContainer__379ep.Header_isOpen__1r0ZF {
  position: relative;
}
.Header_headerContainer__379ep.Header_isOpen__1r0ZF:before {
  content: "";

  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(68, 66, 81, 0.65);
}
.Header_contentContainer__m6vo2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.Header_contentContainer__m6vo2 svg rect:nth-child(3) {
  fill: #fff;
}
.Header_rightSide__3pUVo {
  flex: 1 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.Header_isHomePage__1TOk2 {
  background: #fff;
  border-bottom: 1px solid #E5E4EE;
  position: fixed;
  z-index: 2;
}
.Header_isHomePage__1TOk2 button>svg path {
  fill: #454452;
}
.Header_isHomePage__1TOk2 span>svg path {
  fill: #454452;
}
.Header_isHomePage__1TOk2 a div>svg path {
  fill: #454452;
}
.Header_isHomePage__1TOk2 div a>svg path {
  fill: #454452;
}
.Header_isHomePage__1TOk2 svg rect:nth-child(3){
  fill: #1D1F52;
}
.Header_isHomePage__1TOk2 a,
.Header_isHomePage__1TOk2 .Header_rightSide__3pUVo>div {
  color: #454452;
}
.Header_isHomePage__1TOk2 a {
  color: #454452 !important;
  font-weight: 500;
}
.Header_isHomePage__1TOk2 a:hover,
.Header_isHomePage__1TOk2 a:focus {
  color: #454452;
}
.Button_button__2KJpp {
  height: 46px;
  width: 100%;

  padding: 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  
  border: 1px solid transparent;
  border-radius: 30px;
  text-decoration: none;
  text-transform: uppercase;

  -webkit-transition: .3s;

  transition: .3s;
}
.Button_button__2KJpp:hover {
  cursor: pointer;
}
.Button_button__2KJpp:focus {
  color: #fff;
}
.Button_button__2KJpp>svg {
  margin: 0 10px 0 0 !important;
}

.Button_primary__2vwCQ {
  color: #ffffff;
  background-color: lightblue;
}
.Button_primary__2vwCQ:hover {
  background-color: #2eda8f;
}
.Button_primary__2vwCQ:active {
  background-color: #35c988;
}
.Button_primary__2vwCQ:disabled {
  color: #b4c6dc;
  background-color: #dfe7f2;
}

.Button_secondary__D40TP {
  border: 1px solid #b7b3ce;
  color: #454452;
}
.Button_secondary__D40TP:hover {
  background-color: #f3fffa;
}
.Button_secondary__D40TP:focus {
  color: #454452;
}
.Button_secondary__D40TP:active {
  background: #eafff6;
}
.Button_secondary__D40TP:disabled {
  color: #b4c6dc;
  background: #dfe7f2;
  border: 1px solid transparent;
}
.Button_primaryProvider__wfRxo {
  color: #fff;
  background: #0459FF;
}
.Button_primaryProvider__wfRxo:hover {
  background-color: #2B4AF8;
}
.Button_secondary__D40TP:focus {
  color: #2340E5;
}
.Button_primaryProvider__wfRxo:disabled {
  color: #B4C6DC;
  background: #DFE7F2;
  border: 1px solid transparent;
}
.Button_primaryProviderS__2e2dr {
  color: #fff;
  background: #0459FF;
  height: 38px;
  border-radius: 90px;
}
.Button_primaryProviderS__2e2dr:hover {
  background-color: #2B4AF8;
}
.Button_secondaryS__1pdGY:focus {
  color: #2340E5;
}
.Button_primaryProviderS__2e2dr:disabled {
  color: #B4C6DC;
  background: #DFE7F2;
  border: 1px solid transparent;
}

.Button_link__q3Rka {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  color: #fff;
  text-transform: capitalize;
}

.Button_confirmation__33r82 {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #35C988;
  border: 3px solid lightblue;
  border-radius: 90px;
}
.Button_confirmation__33r82>svg {
  margin: 0 !important;
}
.Dropdown_dropdownContainer__38P7u {
  position: relative;
}
.Dropdown_value__3uwIa {
  white-space: nowrap;
  color: #FFFFFF;
}
.Dropdown_value__3uwIa:hover {
  cursor: pointer;
}
.Dropdown_menu__1hUBB {
  position: absolute;
  top: 0;
  right: 0;
}
.Dropdown_menuContainer__XL_sD {
  position: relative;
  padding: 40px 40px 35px; 
  height: 100%;
  border-top: 3px solid lightblue;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(103, 105, 118, 0.19);
  border-radius: 2px;
}
.Dropdown_menuContainer__XL_sD > svg {
  position: absolute;
  top: -8px;
  left: 0;
}

.HeaderStyles_baseHeaderMobileMenu__2FI62 {
  position: fixed;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #fff;

  margin: 70px 0 0;
  padding: 55px 35px 70px;

  list-style: none;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;

  overflow-y: scroll;
}
.HeaderStyles_visibleMenu__Aqim4 {
  top: 0;
}
.HeaderStyles_baseHeaderMobileMenu__2FI62 > li {
  margin-bottom: 40px;
}
.HeaderStyles_baseHeaderMobileMenu__2FI62 > li > a,
.HeaderStyles_baseHeaderMobileMenu__2FI62 > li > div {
  display: block;
  text-decoration: none;
  font-size: 20px;
  text-transform: none;
  text-transform: initial;
  font-weight: normal;
  line-height: 26px;
  color: #454452;
}
.HeaderStyles_baseHeaderMobileMenu__2FI62 > li > a:hover,
.HeaderStyles_baseHeaderMobileMenu__2FI62 > li > div:hover,
.HeaderStyles_baseHeaderMobileMenu__2FI62 > li > a:focus {
  color: lightblue !important;
}
.HeaderStyles_baseHeaderMobileMenu__2FI62>.HeaderStyles_close__3kKXd {
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 5px;
  margin: 0;
}
.HeaderStyles_baseHeaderMobileMenu__2FI62>.HeaderStyles_close__3kKXd svg path {
  fill: rgb(183, 179, 206);
}
.HeaderStyles_baseHeaderMobileMenu__2FI62 .HeaderStyles_separator__2io3- {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 40px;
}

.HeaderStyles_ButtonLink__1usD9,
.HeaderStyles_Button__pfFHI {
  float: right;
  margin-left: 35px;
  line-height: 17px;
  letter-spacing: .56px;
  font-size: 14px;
  font-weight: 500;
}
a.HeaderStyles_ButtonLink__1usD9 {
  color: lightblue !important;
  text-decoration: none;
}
.HeaderStyles_Button__pfFHI {
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
}
.HeaderStyles_messages__2pus8:hover,
.HeaderStyles_assessmentContainer__2-M4T:hover,
.HeaderStyles_Button__pfFHI:hover,
.HeaderStyles_logoContainer__3iY6Z:hover {
  box-shadow: inset 0px -3px 0px 0px lightblue;
}
.HeaderStyles_Button__pfFHI:focus {
  color: #fff;
}
.HeaderStyles_mailIcon__PV9a8 {
  margin: 0 20px 0 0;
  position: relative;
}
.HeaderStyles_mailIcon__PV9a8 span {
  position: absolute;
  top: -8px;
  right: -8px;
  
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: lightblue;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #38364B;
}
.HeaderStyles_userMenu__3CWkc {
  display: flex;
  flex-direction: column;

  top: 45px;
  right: -10px;
  z-index: 2;
  
  width: 275px;
  height: 220px;
}
.HeaderStyles_userMenu__3CWkc .HeaderStyles_separator__2io3- {
  background-color: #e4e4e4;
  height: 1px;
  width: 100%;
  margin: 0 0 37px;
}
.HeaderStyles_userMenu__3CWkc a {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  color: #454452;
  text-decoration: none;
  margin-bottom: 31px;
}
.HeaderStyles_userMenu__3CWkc a:hover {
  color: lightblue !important;
}
.HeaderStyles_count__c2_M1 {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  background-color: lightblue;
  
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  
  font-weight: 500;
  font-size: 9px;
  line-height: normal;
  color: #000;
}

.HeaderStyles_userMenu__3CWkc a:last-child {
  margin-bottom: 0;
}
.HeaderStyles_userMenu__3CWkc a:hover,
.HeaderStyles_userMenu__3CWkc a:focus {
  color: lightblue;
}
.HeaderStyles_userMenu__3CWkc>.HeaderStyles_menuContainer__ZXz2j {
  height: 100px;
}
.HeaderStyles_mailLink__2e9W_ {
  height: 100%;
  display: flex;
  align-items: center;
}
.HeaderStyles_logoContainer__3iY6Z {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-transition: all .3s;
  transition: all .3s;
  margin-left: 16px;
}
.HeaderStyles_assessmentContainer__2-M4T {
  height: 100%;
  display: flex;
  align-items: center;
  -webkit-transition: all .3s;
  transition: all .3s;
  margin-left: 35px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.HeaderStyles_assessmentContainer__2-M4T>a {
  text-decoration: none;
  color: #fff;
}
.HeaderStyles_assessmentContainer__2-M4T .HeaderStyles_userMenu__3CWkc {
  height: auto;
  top: 35px;
}
.HeaderStyles_messages__2pus8 {
  margin-left: 35px;
  margin-right: 20px;
  
  position: relative;
  text-decoration: none;
  
  font-size: 14px;
  font-weight: 500;
  color: #fff;

  height: 100%;
  display: flex;
  align-items: center;
}
.HeaderStyles_withCount__274uh {
  padding-right: 20px;
  margin-right: 0;
}
.HeaderStyles_messages__2pus8:focus {
  color: #fff;
}
.HeaderStyles_counterContainer__1eEp2 {
  position: relative;
}
.HeaderStyles_counterContainer__1eEp2>div {
  position: absolute;
  right: -20px;
  top: 0;
  
  width: 15px;
  height: 15px;
  
  background-color: lightblue;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  
  font-weight: 500;
  font-size: 9px;
  line-height: normal;
  color: #000;
}

.HeaderStyles_nameContainer__l1IIV {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}
.HeaderStyles_nameContainer__l1IIV>span {
  flex: 1 1;
  margin-left: 15px;
}
.HeaderStyles_ButtonMail__1ZYxK {
  height: 46px;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  border: 1px solid transparent;
  border-radius: 30px;
  text-decoration: none;
  -webkit-transition: .3s;
  transition: .3s;
  height: 100%;
  border-radius: 0;
  border-bottom: 0;
  text-transform: none;
  text-transform: initial;
}
.Footer_footer__3rV0G {
  height: 80px;
  border-top: 1px solid #e4e4e4;
}
.Footer_chats__27e9_ .Footer_rightSide__1YyVp {
  display: none;
}

.Footer_footerMobile__3rh_c {
  height: auto;
  border-top: 0;
}
.Footer_footerContainer__jtUIt {
  display: flex;
  flex-wrap: wrap;
  padding-top: 17px;
}
.Footer_footerContainerMobile__3cff- {
  border-top: 1px solid #e4e4e4;
  padding: 37px 30px 40px !important;
  align-items: flex-start;
}
.Footer_footerContainer__jtUIt p {
  color: #5c5b66;
  font-size: 14px;
}
.Footer_links__31C-c {
  font-size: 14px;
  margin-bottom: 9px;
}
.Footer_links__31C-c>a {
  margin-right: 9px;
}
.Footer_links__31C-c>a:last-child {
  margin-right: 0;
}
.Footer_rightSide__1YyVp {
  display: flex;
  flex-direction: column;
}
.Footer_rightSide__1YyVp>p {
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 26px;
}
.Footer_rightSide__1YyVp>.Footer_link__3VVyR {
  margin-right: 12px;
}
.Footer_rightSideMobile__2BJbr {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Footer_rightSideMobile__2BJbr > p {
  width: 100%;
  margin: 0 0 26px;
}

.Footer_footerContainerMobile__3cff- .Footer_bottom__H3QmE {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 27px 0 0;
}
.Footer_footerContainerMobile__3cff- .Footer_bottom__H3QmE .Footer_links__31C-c {
  margin-bottom: 27px;
}
.Footer_link__3VVyR:last-child {
  margin-right: 0;
}
.Footer_footer__3rV0G .Footer_address__3rkPn {
  font-weight: normal;
}
.Footer_address__3rkPn svg {
  margin: 0 5px 0 1px;
}
.Footer_linkContainer__2hLKG {
  margin-bottom: 44px;
}
.Footer_linkContainer__2hLKG a {
  margin-right: 25px;
}
.Footer_linkContainer__2hLKG a:last-child {
  margin-right: 0;
}
.SimpleSelect_formControl__15sKz {
  min-width: 200px;
}
.Table_root__2EQQF {

}
.Table_table__d5FyE {

}
.Table_tableRow__2pXMb {
  width: 200px;
}
.UserContainer_userContainer__RAdSu {
  display: flex;
  margin: 40px auto;
  flex: 1 1;
  max-width: 910px;
}
.UserContainer_formContainer__C_u4O h1 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}

.UserContainer_error__1Wjjz {
  color: red;
}
.HomePage_homeContainer__2Rngt {
  display: flex;
  flex-direction: column;

  flex: 1 1;
  max-width: 798px;
  margin: auto;
}

.BaseContainer_baseContainer__1Rfr8 {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.Loading_wrapper__1QiiY {
  position: relative;
  width: 100%;
  flex: 1 1;
}

.Loading_loader__2jJFx {
	height: 32px;
  width: 32px;
  display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}

.Loading_loader__2jJFx::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	background: lightblue;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-1__rJM13 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-1__rJM13 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-1__rJM13 {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
@keyframes Loading_loader-4-1__rJM13 {
	0%   { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
	50%  { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
	100% { -webkit-transform: scale(0); transform: scale(0); opacity: 0; }
}
.Loading_loader__2jJFx span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 32px;
	width: 32px;
  -webkit-animation: Loading_loader-4-2__DwWXO 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-2__DwWXO 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-2__DwWXO {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes Loading_loader-4-2__DwWXO {
	0%   { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.Loading_loader__2jJFx span::before,
.Loading_loader__2jJFx span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 12px;
	width: 12px;
	background: lightblue;
	border-radius: 50%;
  -webkit-animation: Loading_loader-4-3__bVe4_ 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-3__bVe4_ 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-3__bVe4_ {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-3__bVe4_ {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(-16px, 0, 0) scale(.5); transform: translate3d(-16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
.Loading_loader__2jJFx span::after {
  -webkit-animation: Loading_loader-4-4__1OmxA 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
          animation: Loading_loader-4-4__1OmxA 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}
@-webkit-keyframes Loading_loader-4-4__1OmxA {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}
@keyframes Loading_loader-4-4__1OmxA {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
	50%  { -webkit-transform: translate3d(16px, 0, 0) scale(.5); transform: translate3d(16px, 0, 0) scale(.5); }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); transform: translate3d(0, 0, 0) scale(1); }
}


.TextBox_input__2djm_{
  width: 100%;
}
.TextBox_input__2djm_ label {
  color: #858297 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}
.TextBox_error__15bGu {
  position: absolute;
  top: 65px;
  margin-top: 0 !important;
}
.TextBox_errorTextArea__12iMT {
  position: absolute;
  bottom: -20px;
  margin-top: 0 !important;
}
.TextBox_input__2djm_ label[data-shrink="true"] {
  font-size: 14px;
  -webkit-transform: translate(0, 1.5px) scale(1);
          transform: translate(0, 1.5px) scale(1);
}
.TextBox_input__2djm_ div[type="text"] {
  display: flex;
  padding: 0;
}
.TextBox_input__2djm_ div[type="text"] > div {
  padding: 0;
  font-weight: 500;
}

.TextBox_input__2djm_>div:before,
.TextBox_input__2djm_>div:hover:before {
  border-bottom: 1px solid #b7b3ce !important;
}
.TextBox_input__2djm_>div:after {
  border-bottom: 1px solid lightblue;
}
.TextBox_input__2djm_ input {
  padding: 14px 0 10px;
  color: #454452;
  font-weight: 500;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.04em;
}
.TextBox_input__2djm_ textarea {
  min-height: 30px;
  color: #454452;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.04em;
}
.TextBox_visibilityIcon__32sIV {
  color: lightblue;
}
.TextBox_password__22PWs {
  position: relative;
}
.TextBox_toggleVisibility__2YIeV {
  position: absolute;
  right: 0;
  top: 29px;
  
  padding: 0;
  
  width: 25px;
  height: 20px;
}
.TextBox_toggleVisibility__2YIeV>svg {
  width: 25px;
  height: 20px;
}
.TextBox_toggleVisibility__2YIeV:hover {
  cursor: pointer;
}
.TextBox_password__22PWs button {
  background-color: transparent;
}
.TextBox_password__22PWs input {
  padding-right: 25px;
}
.LoginPage_loginPage__2I_cX {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
}
.LoginPage_resend__14juX {
  background: #eafff6;
}
.LoginPage_loginPageMobile__342BI {
  padding: 30px 0;
}
.LoginPage_LoginScreenTitle__3jlz9 {
  color: lightblue;
  font-size: 26px;
  font-weight: 400;
  line-height: 31px;
  margin: 30px 0;
}
.LoginPage_LoginScreenTitleMobile__1ZsLE {
  font-size: 24px;
  line-height: 29px;
}
.LoginPage_LoginScreenContainer__1oSPi > p {
  font-size: 14px;
  color: #454452;
  margin: 20px 0;
}
.LoginPage_button__2izFR {
  margin-top: 12px;
}
/*Welcome screen*/
.LoginPage_loginPageContent__36QoA {
  height: 531px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.LoginPage_title__1s-9Q {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .04em;
  margin: 0;
}
.LoginPage_loginPage__2I_cX .LoginPage_footer__3UyYQ {
  color: #5c5b66;
  font-size: 14px;
  margin: 0;
}
.LoginPage_loginPage__2I_cX .LoginPage_modalFooter__3RLJ6 {
  margin: 45px 0 25px;
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #454452;
}
.LoginPage_modalButton__2A8OZ {
  margin-bottom: 0 !important;
}
/*Login Form*/
.LoginPage_loginForm__18ocE {
  width: 240px;
  margin-top: 51px;
  flex: 1 1;
}
.LoginPage_loginFormMobile__1t7R5 {
  margin-top: 33px;
}
.LoginPage_formItems__2ckzW>div {
  margin-bottom: 36px;
}
.LoginPage_formItems__2ckzW>div:last-child {
  margin-bottom: 2px;
}

.LoginPage_submitButton__3PJGE {
  max-width: 136px;
  margin: 24px auto 0;
}

.LoginPage_loginFooter__2GCTV {
  margin: 18px auto 0;
  color: #5c5b66;
  font-size: 14px;
}
.LoginPage_checkBoxContainer__2msI- {
  margin-top: 14px;
  font-size: 14px;
  text-align: start;
  color: #5c5b66;
}
.LoginPage_checkBoxLabel__1_oBk {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .56px;
  color: #5c5b66;
  margin-top: 16px;
  margin-left: -19px;
  text-transform: capitalize;
}
.LoginPage_errorContainer__32Uc4 {
  position: relative;
}
.LoginPage_loginError__HhrhW {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 8px;
  line-height: 1em;
  position: absolute;
  top: -15px;
  text-align: left;
}
.LoginPage_LoginScreenContainer__1oSPi {
  width: 280px;
  flex: 1 1;
  margin-top: 30px;
}



.LoginPage_verificationContainer__2M32T {
  margin-top: 57px;
  flex: 1 1;
}
.LoginPage_verificationContainerMobile__3jbme {
  margin-top: 30px;
}
.LoginPage_verificationContainer__2M32T h1 {
  margin: 0 0 37px;
  color: lightblue;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.LoginPage_verificationContainerMobile__3jbme h1 {
  margin: 0 0 24px;
  font-size: 32px;
  letter-spacing: .96px;
}
.LoginPage_verificationContainer__2M32T h3 {
  margin: 0 auto 48px;
  color: #5c5b66;
  font-size: 26px;
  font-weight: 400;
  line-height: 32.6px;
  max-width: 570px;
}
.LoginPage_verificationContainerMobile__3jbme h3 {
  margin: 0 auto 62px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.LoginPage_verificationContainer__2M32T>svg {
  margin-left: -40px;
}
.LoginPage_verificationContainer__2M32T p {
  margin: 0 auto;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 209px;
}
p.LoginPage_verificationDescription__2jjlx {
  height: 53px;
  margin: 40px auto 42px;
  color: #454452;
  font-size: 20px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 400;
  line-height: 26px;
}
.LoginPage_verificationContainerMobile__3jbme p.LoginPage_verificationDescription__2jjlx {
  margin: 42px auto 63px;
  color: #454452;
  font-size: 18px;
  line-height: 23.6px;
  max-width: 220px;
}

.ForgotPage_forgotPage__1B-G1 {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  text-align: center;

  flex-direction: column;
  padding: 40px 0;
  min-height: calc(100vh - 80px);
}

.ForgotPage_forgot__2BuA8 {
  background: #eafff6;
}
.ForgotPage_forgotPageMobile__3zeRu {
  padding-top: 30px;
}

.ForgotPage_forgotPage__1B-G1 > p {
  color: #b1afba;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .56px;
  margin: 0;
}

.ForgotPage_forgotForm__2wsz0 {
  margin-top: 51px;
  width: 240px;
}
.ForgotPage_submitButton__1S0Bv {
  max-width: 176px;
  margin: 36px auto 0;
}
.ForgotPage_forgotError__owhOI {
  color: #f44336;
  font-size: 0.75rem;
  margin: 8px auto;
  line-height: 1em;
  text-align: left;
}

.ForgotPage_forgotContainer__2xZSq {
  margin-top: 57px;
}
.ForgotPage_forgotContainerMobile__2Obn8 {
  margin-top: 38px;
}
.ForgotPage_forgotContainer__2xZSq h1 {
  margin: 0 0 27px;
  color: lightblue;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.14px;
  text-transform: capitalize;
}
.ForgotPage_forgotContainerMobile__2Obn8 h1 {
  margin: 0 0 24px;
  font-size: 32px;
  letter-spacing: .96px;
}
.ForgotPage_forgotContainer__2xZSq h3 {
  margin: 0 0 46px;
  color: #5c5b66;
  font-size: 26px;
  font-weight: 400;
  line-height: 32.6px;
}
.ForgotPage_forgotContainerMobile__2Obn8 h3 {
  margin: 0 auto 26px;
  font-size: 24px;
  line-height: 31.5px;
  max-width: 259px;
}
.ForgotPage_forgotContainer__2xZSq>svg {
  margin-left: -40px;
}
.ForgotPage_forgotContainer__2xZSq p {
  margin: 0 auto;
  color: #5c5b66;
  font-size: 14px;
  line-height: 20.5px;
  max-width: 260px;
}
.ForgotPage_forgotContainer__2xZSq .ForgotPage_Button__2A777 {
  margin: 44px auto 90px;
  max-width: 240px;
}
.ForgotPage_forgotContainerMobile__2Obn8 .ForgotPage_Button__2A777 {
  margin: 42px auto 59px;
}
.ForgotPage_forgotContainer__2xZSq .ForgotPage_Button__2A777 p {
  color: #fff;
}
.AccountContainer_accountContainer__3CLh8 {
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  
}
html {
  height: 100%;
  overflow: overlay;
}

body {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
html, body {
  max-width: 100%;
}
/* @media only screen and (max-width: 768px) {
  html, body {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
} */
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: 'Roboto', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a {
  font-size: 14px;
  text-transform: capitalize;
  text-decoration: underline;
  overflow: hidden;
  color: lightblue;
}
a:focus {
  color: #35c988;
}
a[tabIndex]:focus {
  color: #35c988;
  outline: none;
}
a:hover {
  cursor: pointer;
}

a:disabled {
  color: #b4c6dc;
}
button {
  border: 0;
  padding: 0;
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  box-shadow: 0 0 0px 1000px #FFFFFF inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
a[disabled] {
  pointer-events: none;
  color: #b4c6dc;
}
a[disabled] svg path {
  fill: #b4c6dc;
}

/*custom scroll*/
body::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar {
  width: 0px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar>* {
  background:transparent;
}
body::-webkit-scrollbar-thumb {
  background: #E7E5F1; 
  border-radius: 80px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #E1DFEF;
}
body::-webkit-scrollbar-thumb:active {
  background: #D1CFE5;
}

