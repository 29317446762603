.userContainer {
  display: flex;
  margin: 40px auto;
  flex: 1;
  max-width: 910px;
}
.formContainer h1 {
  margin: 0 0 22px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .8px;
  color: #454452;
}

.error {
  color: red;
}